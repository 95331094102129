import axios from "../service/axios";
import i18n from "../i18n";

export default {
  state: {
    orders: [],
    totalOrdersCount: 0,
  },

  mutations: {
    setOrders(state, orders) {
      state.orders = orders;
    },

    addOrder(state, order) {
      if (Array.isArray(state.orders)) {
        state.orders.unshift(order);
      } else {
        state.orders = [order];
      }
    },

    updateOrder(state, order) {
      state.orders = state.orders.map((x) => {
        if (x.id == order.id) return order;
        return x;
      });
    },

    removeOrder(state, orderId) {
      state.orders = state.orders.filter((x) => x.id != orderId);
    },

    setTotalOrdersCount(state, totalOrdersCount) {
      state.totalOrdersCount = totalOrdersCount;
    },
  },

  actions: {
    async orderAccept({ commit, dispatch }, order) {
      try {
        let res = await axios.post("order-accept", { order_id: order.id });
        if (res.status != 200) return showMsg(0, 0, dispatch);

        if (res.data.status == 0) {
          showMsg(0, res.data.error, dispatch);
          return;
        }

        order.status = res.data.order_status;
        commit("updateOrder", order);
        let text = i18n.t("Successfully!");
        showMsg("success", text, dispatch);
      } catch {
        showMsg(0, 0, dispatch);
      }
    },

    async orderPickedUp({ commit, dispatch }, order) {
      try {
        let res = await axios.post("order-picked-up", { order_id: order.id });
        if (res.status != 200) return showMsg(0, 0, dispatch);

        if (res.data.status == 0) {
          showMsg(0, res.data.error, dispatch);
          return;
        }

        order.status = res.data.order_status;
        commit("updateOrder", order);
        let text = i18n.t("Successfully!");
        showMsg("success", text, dispatch);
      } catch {
        showMsg(0, 0, dispatch);
      }
    },

    async orderChangeStore({ commit, dispatch }, orderChangeStore) {
      try {
        let res = await axios.post("order-change-store", orderChangeStore);

        if (res.status != 200) {
          showMsg(0, 0, dispatch);
          return false;
        } else if (res.data.status == 0) {
          showMsg(0, res.data.error, dispatch);
          return false;
        } else {
          let text = i18n.t("Successfully!");
          showMsg("success", text, dispatch);
          commit("removeOrder", orderChangeStore.order_id);
          return true;
        }
      } catch {
        showMsg(0, 0, dispatch);
        return false;
      }
    },

    async getOrdersApi({ commit }, options) {
      let {
        page,
        itemsPerPage,
        status,
        search,
        type,
        payment_type,
        store,
        created_at__gte,
        created_at__lte,
      } = options;

      let url = `v1/store-orders?lang=ru&page=${page}&per_page=${itemsPerPage}&status=${status}&type=${type}&payment_type=${payment_type}&store=${store}`;

      if (search?.trim()) {
        url += `&search=${search}`;
      }
      if (created_at__gte?.trim()) {
        url += `&created_at__gte=${created_at__gte}` + ` 00:00:00`;
      }
      if (created_at__lte?.trim()) {
        url += `&created_at__lte=${created_at__lte} 23:59:59`;
      }

      let result = { results: [], count: 0 };
      try {
        const res = await axios.get(url);
        result = res.data;
      } catch {
        //
      }

      let items = result.results;
      const total = result.count;

      commit("setOrders", items);
      commit("setTotalOrdersCount", total);

      return {
        items,
        total,
      };
    },

    addOrder({ commit, state }, order) {
      commit("setTotalOrdersCount", state.totalOrdersCount + 1);
      commit("addOrder", order);
    },

    updateOrder({ commit }, order) {
      commit("updateOrder", order);
    },
  },

  getters: {
    getOrders: (state) => state.orders,
    getTotalOrdersCount: (state) => state.totalOrdersCount,
  },
};

function showMsg(color, text, dispatch) {
  if (!color) color = "error";
  if (!text) text = i18n.t("errorLoadingTheList");
  dispatch("setSnkMsg", { text, color });
}
