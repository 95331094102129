<template>
  <div class="map-container">
    <div id="map" ref="mapRef" class="h-100 w-100"></div>
    <div id="marker"></div>
    <div class="zoom-controls">
      <button
        @click="getLocation"
        class="flex justify-center items-center gap-2 bg-blue-500 text-white py-2 px-4 rounded-lg text-sm leading-6 font-semibold hover:bg-blue-600 focus:bg-blue-700"
      >
        {{ btnText }}
      </button>
    </div>
  </div>
</template>

<script>
import sysConfig from "../utils/staticConfig.js";

export default {
  props: {
    value: {
      type: Array,
      default: () => [41.311151, 69.279737],
    },
    zoom: {
      type: Number,
      default: 20,
    },
    btnText: {
      type: String,
      default: 'Укажите адрес доставки',
    },
  },

  data() {
    return {
      myMap: null,
    };
  },

  computed: {
    _coords: {
      get() {
        return this.value;
      },
      set(coords) {
        this.$emit('input', coords);
      },
    },
  },

  mounted() {
    this.initMap();
  },

  methods: {
    loadYandexMapsScript() {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://api-maps.yandex.ru/2.1/?lang=ru_RU&apikey=${sysConfig.yandexApi}`;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    },

    async initMap() {
      try {
        if (!window.ymaps) {
          await this.loadYandexMapsScript();
        }
        window.ymaps.ready(this.createMap);
      } catch (error) {
        console.error('Failed to load Yandex Maps script', error);
      }
    },

    createMap() {
      this.myMap = new window.ymaps.Map(
        this.$refs.mapRef,
        {
          center: this._coords,
          zoom: this.zoom,
          controls: [],
        },
        {
          suppressMapOpenBlock: true,
          yandexMapDisablePoiInteractivity: true,
          copyrightLogoVisible: false,
          copyrightProvidersVisible: false,
          copyrightUaVisible: false,
        }
      );

      const zoomControl = new window.ymaps.control.ZoomControl({
        options: {
          position: {
            left: 10,
            top: 10,
          },
        },
      });

      this.myMap.controls.add(zoomControl);

      this.myMap.events.add('actionend', this.onMapActionEnd);
    },

    onMapActionEnd() {
      this._coords = this.myMap.getCenter();
    },

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const coords = [
              position.coords.latitude,
              position.coords.longitude,
            ];
            this.myMap.setCenter(coords, this.zoom);
            this._coords = coords;
          },
          (error) => {
            console.error('Error getting geolocation:', error);
            alert('Error getting your location. Please try again later.');
          }
        );
      } else {
        alert('Geolocation is not supported by this browser.');
      }
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

#marker {
  background-image: url('~/public/img/marker.png');
  width: 33px;
  height: 36px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
}
</style>
