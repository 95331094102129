const valid = {
  req: (v) => !!v || "",
  notNoll: (v) => v > 0 || "",
  reqPhone: (v) => {
    if (v && v.trim()) {
      return v.substr(16) != "_" || "";
    } else {
      return "";
    }
  },
  reqCardNumber: (v) => {
    // Validation check for 16 digits, allowing optional spaces between every four digits
    const cardNumberPattern = /^(\d{4} ?){3}\d{4}$/;
    return cardNumberPattern.test(v) || "Please enter a valid 16-digit card number.";
  },
  reqSms: (v) => {
    // Updated pattern to match exactly 6 digits
    const smsPattern = /^\d{6}$/;
    return smsPattern.test(v) || "Please enter a valid 6-digit SMS code.";
  },

  // reqPhone(v) {
  //   let msg = "Введите правильный номер телефона";

  //   return (v?.trim() && v.substr(17) == "_") || "";

  //   // return `${filedName}.setCustomValidity(${filedName}.value.substr(17) == "_" ? "${msg}" : "")`;
  // }
};

import Vue from "vue";

Vue.prototype.$valid = valid;
