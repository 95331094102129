<script>
// import { mask } from "../../service/input_mask.js";

export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: false,
    },

    dtHeaders: {
      type: Array,
      default: () => [],
    },

    selectedOrder: {
      type: Object,
      default: () => ({
        items: [],
      }),
    },

    getManufacturer: {
      type: Function,
      default: () => {},
    },

    getDrugImg: {
      type: Function,
      default: () => {},
    },
  },

  data: (vm) => ({
    height: 80,
    width: 300,
    selected: {},
    headers: [
      {
        text: "",
        value: "image",
        width: 32,
        sortable: false,
      },
      {
        text: vm.$t("name"),
        value: "name",
      },
      {
        text: vm.$t("Manufacturer"),
        value: "manufacturer",
      },
      {
        text: vm.$t("quantity"),
        value: "qty",
        width: 20,
        sortable: false,
      },
      {
        text: vm.$t("Pharmacy address"),
        value: "store",
        width: 120,
        isManager: true,
      },
      {
        text: vm.$t("priceForOne"),
        value: "price",
        width: 120,
        sortable: false,
      },
      {
        text: vm.$t("totalAmount"),
        value: "subtotal",
        width: 120,
        sortable: false,
      },
      {
        text: "",
        value: "actions",
        sortable: false,
        width: 30,
      },
    ],
    order: {},
  }),

  computed: {
    dialog: {
      get: (vm) => vm.value,

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  created() {
    let ids = this.selectedOrder.items.map((x) => x.drug.id).join(",");
    this.axios.get(`v1/drugs?ids=${ids}`).then((res) => {
      let order = JSON.parse(JSON.stringify(this.selectedOrder));

      order.items = order.items.map((item) => {
        let drug = res.data.results.find((dr) => dr.id == item.drug.id);
        item.isCopy = false;
        item._id = item.id;
        item.stores = drug ? drug.remains : [];
        let store = item.stores.find((x) => x.store_id == item.store.id);
        if (!store) {
          store = {
            id: this.$tools.getUniq(),
            qty: item.qty,
            defect: true,
            store: item.store.name,
            store_id: item.store.id,
          };
          item.stores.push(store);
        }

        item.selectedStore = store ? store : {};
        return item;
      });

      this.order = order;
    });
  },

  mounted() {
    this.height = document.body.offsetHeight - 230;
    this.width = document.body.offsetWidth - 100;
  },

  methods: {
    onClose() {
      this.dialog = false;
    },

    onSave() {
      let param = {
        order_id: this.order.id,
        items: this.order.items
          .map((x) => ({
            id: x.isCopy ? "new" : x.id,
            drug_id: x.drug.id,
            qty: Number(x.qty),
            store_id: x.selectedStore.store_id,
          }))
          .filter((x) => x.qty > 0),
      };
      this.axios.post("v1/order-update", param).then((res) => {
        if (res.data.status == 0 || res.data.error != "") {
          this.$store.dispatch("setSnkMsg", { text: res.data.error });
        } else {
          this.$emit("seved");
          this.dialog = false;
        }
      });
    },

    onCopy(drug, index) {
      let order = JSON.parse(JSON.stringify(this.order));
      let copy = JSON.parse(JSON.stringify(drug));
      let st = "selectedStore";

      copy[st] = {};
      copy.stores = drug.stores.filter((x) => x.store_id != drug[st].store_id);
      copy.qty = 0;
      copy.id = 0;
      copy.isCopy = true;
      copy.subtotal = 0;
      copy._id = this.$tools.getUniq();
      order.items.splice(index, 0, copy);
      this.order = order;
    },

    getSubTotal(item) {
      let qty = `${item.qty}`.replace(/ /g, "");
      return qty * item.price;
    },

    onDelete(item) {
      let order = { ...this.order };
      order.items = order.items.filter((x) => x._id != item._id);
      this.order = order;
    },

    onChangeStore(item) {
      if (item.qty > item.selectedStore.qty) item.qty = item.selectedStore.qty;
      this.order = JSON.parse(JSON.stringify(this.order));
      // mask.number(`#${strId}`, item.selectedStore.qty);
    },

    getIsDisableCopy: (item) =>
      !item.selectedStore.id || item.stores.length < 2,
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog persistent v-model="dialog" :max-width="width">
      <v-card>
        <v-card-title class="pb-5 d-flex">
          <v-spacer />

          <v-btn icon @click="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card elevation="0" class="w-100 h-100 ma-0 pa-0">
            <v-data-table
              disable-pagination
              fixed-header
              :height="height - 100"
              :headers="headers"
              :items="order.items"
              disable-sort
              hide-default-footer
              :mobile-breakpoint="0"
              dense
              item-key="_id"
            >
              <template v-slot:body="props">
                <tbody>
                  <tr
                    @click="selected = item"
                    :key="key"
                    v-for="(item, key) in props.items"
                    :class="item._id == selected._id ? 'dt-active' : ''"
                  >
                    <td @click="$tools.openImg(item.drug.image_thumbnail)">
                      <img
                        width="32"
                        :src="getDrugImg(item.drug.image_thumbnail)"
                        :alt="key"
                      />
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.drug.name }}</div>
                    </td>
                    <td>
                      <div class="text_nowrap">
                        {{ getManufacturer(item.drug) }}
                      </div>
                    </td>
                    <td style="position: relative">
                      <input
                        :disabled="!item.selectedStore.id"
                        class="item-qty"
                        :id="`item-qty-${item._id}`"
                        type="text"
                        v-model="item.qty"
                      />
                      <!-- v-mask="{
                          alias: 'numeric',
                          groupSeparator: ' ',
                          digits: 0,
                          min: 0,
                          max: item.selectedStore.qty,
                          autoUnmask: true,
                          positionCaretOnClick: 'select'
                        }" -->
                      <span
                        v-if="item.selectedStore.qty"
                        class="item-qty-description"
                      >
                        max:
                        {{
                          item.selectedStore.defect
                            ? 0
                            : item.selectedStore.qty | sum
                        }}
                      </span>
                    </td>
                    <td>
                      <v-select
                        class="dt-select"
                        height="36px"
                        outlined
                        hide-details
                        v-model="item.selectedStore"
                        @input="onChangeStore(item, `item-qty-${item._id}`)"
                        :items="item.stores"
                        item-text="store"
                        return-object
                        dense
                      />
                    </td>
                    <td>
                      <div class="text_nowrap">{{ item.price | sum }}</div>
                    </td>
                    <td>
                      <div class="text_nowrap">
                        {{ getSubTotal(item) | sum }}
                      </div>
                    </td>
                    <td>
                      <div class="d-flex" style="width: 50px">
                        <v-icon
                          :disabled="getIsDisableCopy(item)"
                          tabindex="-1"
                          @click="onCopy(item, key)"
                        >
                          mdi-content-copy
                        </v-icon>
                        <v-icon
                          class="ml-2"
                          :disabled="!item.isCopy"
                          tabindex="-1"
                          @click="onDelete(item)"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>

            <div class="d-flex px-9 py-2">
              <v-spacer />

              <v-btn color="primary" @click="onSave" dark>
                {{ $t("Save") }}
              </v-btn>
            </div>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<style lang="scss" scoped>
.item-qty {
  height: 36px;
  width: 80px;
  padding: 0 5px;
  border: 1px solid #929292;
  border-radius: 4px;
  text-align: left !important;
  margin-top: 5px;
}

.item-qty:focus {
  outline: none;
  border-color: #1976d2;
}

.item-qty-description {
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 16px;
  background: white;
  z-index: 2;
  line-height: 12px;
}

.dt-select {
  width: 240px;
}
</style>
