import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import User from "./User.js";
import StoreOrders from "./StoreOrders.js";
import AddOrder from "./AddOrder.js";
import Chat from "./Chat.js";
import DefaultRegion from "./DefaultRegion.js";

export default new Vuex.Store({
  modules: [User, StoreOrders, AddOrder, Chat, DefaultRegion],

  state: {
    snkMsg: {
      text: "",
      color: "success",
      isShow: false,
    },
  },

  mutations: {
    setSnkMsg(state, snkMsg) {
      if (!snkMsg) snkMsg = {};
      snkMsg.color = snkMsg.color || "red";
      snkMsg.isShow = true;
      state.snkMsg = snkMsg;
    },
    hideSnkMsg(state) {
      state.snkMsg.isShow = false;
    }
  },

  actions: {
    setSnkMsg({ commit, dispatch }, snkMsg) {
      commit("setSnkMsg", snkMsg);
      dispatch('hideSnkMsgAfterDelay');
    },
    hideSnkMsgAfterDelay({ commit }) {
      setTimeout(() => {
        commit('hideSnkMsg');
      }, 3000); // 3000 milliseconds = 3 seconds
    }
  },

  getters: {
    getSnkMsg: (state) => state.snkMsg,
  },
});
