const bStore = {
  delLocal(a) {
    localStorage.removeItem(a);
  },

  setLocal(a, v) {
    localStorage.setItem(a, JSON.stringify(v));
  },

  getLocal(v) {
    let u = localStorage.getItem(v);
    if (u && u != "undefined") return JSON.parse(u);
  },

  localClear() {
    localStorage.clear();
  },

  delSession(a) {
    sessionStorage.removeItem(a);
  },

  setSession(a, v) {
    sessionStorage.setItem(a, JSON.stringify(v));
  },

  getSession(a) {
    let u = sessionStorage.getItem(a);
    if (u) return JSON.parse(u);
  },

  sessionClear() {
    sessionStorage.clear();
  },

  removeUser() {
    this.delLocal("userTokenData");
  },

  setUser(userData) {
    this.setLocal("userTokenData", userData);
  },

  getUser() {
    return this.getLocal("userTokenData");
  },

  getToken() {
    let token = null;
    const userTokenData = this.getLocal("userTokenData");
    if (userTokenData) {
      token = userTokenData.token;
    }

    return token ? `bearer ${token}` : null;
  },

  getTokenOnly() {
    let token = null;
    const userTokenData = this.getLocal("userTokenData");
    if (userTokenData) {
      token = userTokenData.token;
    }

    return token ? token : null;
  },
};

import Vue from "vue";

export default bStore;
Vue.prototype.$bStore = bStore;
