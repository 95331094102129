<script>
import { loadYmap } from "vue-yandex-maps";
import sysConfig from "../utils/staticConfig.js";

export default {
  props: {
    existsStores: {
      type: Array,
      required: true,
    },

    zoom: {
      type: [String, Number],
      default: () => 11,
      required: false,
    },
  },

  data: () => ({
    settings: {
      apiKey: sysConfig.yandexApi,
      lang: "ru_RU",
      coordorder: "latlong",
    },
  }),

  mounted() {
    loadYmap({ ...this.settings, debug: true }).then(() => {
      const stores = JSON.parse(JSON.stringify(this.existsStores));
      let center = stores[0].location.coordinates.reverse();
      const ymaps = window.ymaps;
      let myMap = new ymaps.Map(
          "yandex-map-stores",
          {
            center: center.map((x) => x.toFixed(2)),
            zoom: this.zoom,
          },
          {
            searchControlProvider: "yandex#search",
          }
        ),
        objectManager = new ymaps.ObjectManager({
          clusterize: true,
          gridSize: 32,
          clusterDisableClickZoom: true,
        });

      myMap.geoObjects.add(objectManager);

      let markers = this.getMarkers();

      objectManager.add(markers);
      objectManager.objects.events.add("click", this.onClick);
    });
  },

  methods: {
    onClick(e) {
      let id = e.get("objectId");
      this.$emit("select", id);
    },

    getMarkers() {
      let features = this.existsStores.map((x, inx) => this.getFeature(x, inx));

      features.unshift(this.getFeature(this.existsStores[0]));

      return {
        type: "FeatureCollection",
        features,
      };
    },

    getFeature(store) {
      let coordinates = store.location.coordinates
        .reverse()
        .map((x) => x.toFixed(2));

      return {
        type: "Feature",
        id: store.id,
        geometry: {
          type: "Point",
          coordinates,
        },

        properties: {
          iconContent: `<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAACsklEQVQ4y6VTS0hUYRS+jY8WtRCseeErpJ0PBLHIdObO1NLSGTMzMIxaVyhKROZCshlnNBXbFOUzsuwhBdWuLDImLegBauW9PgK1NMokdO69p+/cudaidv1wOPPPf77vfOdxBQEn3y+bDJ8Ae+TwywRT832S6gxMKdtq34RTym+vWr1dYdhDmJXjrZ6udUK+TwZQEnQCnzy4q/07gxWACWDaXveObMU9ZC68QgAq9rIBsng6B3QCLxP45SgDXOFq+UIArjIYpjkbJ9UtFQPq5r2XVWtxtwogFHQz0TR+xwtrByRxsBln8NOadE0MTlNWzTCZizrJtq+XrMUwgG2lN5hg3OLtjP1N4PDJQXfbIoGEs2uOxknKa/ioJZZdnzMXdcwDMI+M7Gfhl6GomnEoy8TgDMhXuXHwGgjCrtYF2nn2fZ2woXAjSBIBsttLehNM2WfiMiqfufPOTVQywY76MZ3ggev8PMGHHT5JEZtmuf63pf0UJfzjoLHtYrMekxqR75/ki6FAUsXmOfYF/JZZORQTX3DRlH7iaQzfkTkXZRIrRHyfMTrphcG4Yjzc4v+rR+mv7Ih5LLJa9IobjrsTJUiiMzBDrAQPP3Lrx9I4eOvR+7FoUnTascH1evaGD0dcLZ8ZxDuiGElDBvNE9+4LS5R+/MnCpj2XnmPmL9G4YXR9xOLpGLGXXA2hqUvOwLS+Hw5uNMjgb+oEmHky6vuWXH6HLN5espVcIyvMtr+PzJ4eyqgKkdikS9YiY55i8DIsRcisGopmkpza1yeTDt3D4nT85JWFCgUlKEkH+3XJKI/BXOaKu3WRCU7r2VMP3zWmIUdj1qM2zA8EKoiYjLJPvdLEiHT9G3G3fWXwOOzPJmJZ9K8RgByAx1hBwoE+JasmpGDuqjFmzaifwTmRDZZMwv+eXwZWvBMq+HXgAAAAAElFTkSuQmCC" alt="logo" />`,
          iconCaption: "Аптека",
          hintContent: `<h3 style="padding-bottom:5px">${store.name}</h3>
                          <div>Адрес: ${store.address}</div>
                          <div>Телефон: ${this.$tel(store.phone)}</div>`,
        },

        options: {
          preset: "islands#redIcon",
        },
      };
    },
  },
};
</script>

<template>
  <div class="w-100 h-100">
    <div class="h-100 w-100" id="yandex-map-stores"></div>
  </div>
</template>

<style scoped>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
</style>
