import browserStore from "../service/browserStore";

export default {
  state: {
    defaultRegion: {
      id: 1,
      name: "Ташкент",
      childs: [],
    },
  },

  mutations: {
    setDefaultRegion(state, defaultRegion) {
      state.defaultRegion = defaultRegion ? defaultRegion : null;
      browserStore.setLocal("defaultRegion", state.defaultRegion);
    },
  },

  actions: {
    initDefaultRegion({ commit }) {
      let local = browserStore.getLocal("defaultRegion");
      if (local) commit("setDefaultRegion", local);
    },

    setDefaultRegion({ commit }, region) {
      commit("setDefaultRegion", region);
    },
  },

  getters: {
    getDefaultRegion: (state) => state.defaultRegion,
    getDefaultRegionId(state) {
      if (state.defaultRegion) {
        let region = state.defaultRegion;
        return region.district ? region.district.id : region.id;
      } else {
        return null;
      }
    },
  },
};
