<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: false,
    },
    item: Number,
  },

  data: (vm) => ({
    loading: false,
    orderChangeStore: {
      order_id: vm.item,
      comment: null,
    },
  }),

  computed: {
    dialog: {
      get: (vm) => vm.value,

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    async onSave() {
      if (!this.$refs.commentForChangeStoreForm.validate()) return;
      this.loading = true;
      let change = { ...this.orderChangeStore };
      const status = await this.$store.dispatch("orderChangeStore", change);

      if (status) this.$emit("close");
      this.loading = false;
      this.dialog = false;
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="500px">
      <v-card :loading="loading">
        <v-card-title class="pb-5 d-flex">
          {{ $t("descriptionForCancellation") }}

          <v-spacer />

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="commentForChangeStoreForm">
            <v-textarea
              autofocus
              autocomplate="off"
              outlined
              name="comment"
              :rules="[$valid.req]"
              v-model="orderChangeStore.comment"
            />
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">
            {{ $t("close") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="onSave">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
