<script>
export default {
  props: {
    width: {
      type: Number,
      required: false,
    },

    products: {
      type: Array,
      default: () => [],
      required: false,
    },

    loading: Boolean,
  },

  data: () => ({
    maxHeight: 100,
  }),

  mounted() {
    this.maxHeight = window.innerHeight - 100 + "px";
  },

  methods: {
    onSelect(product) {
      this.$emit("selected", product);
    },
  },
};
</script>

<template>
  <v-card :loading="loading" :maxHeight="maxHeight">
    <v-list>
      <v-list-item
        v-for="(item, index) in products"
        :key="index"
        @click="onSelect(item)"
      >
        <v-list-item-avatar @click="$tools.openImg(item.image_thumbnail)">
          <v-img
            lazy-src="/media/drugs-thumbnail/default.png"
            :src="item.image_thumbnail"
          />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            <div class="drug-name">
              {{ item.name }}
            </div>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          {{ item.price | sum }} {{ $t("sum") }}
        </v-list-item-action>
      </v-list-item>
      <v-list-item-subtitle v-if="products.length == 0" class="text-center">
        {{ $t("No data") }}
      </v-list-item-subtitle>
    </v-list>
  </v-card>
</template>

<style scoped>
.drug-name {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}
</style>
