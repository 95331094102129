import Vue from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store";

import vueInputmask from "./plugins/vue-inputmask.js";
Vue.use(vueInputmask);
import { VueMaskDirective } from 'v-mask'; // Import the v-mask directive

import "./service/axios";
import "./service/tools.js";
import "./service/filters.js";
import "./service/browserStore.js";
import "./service/validators.js";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import i18n from "./i18n";
import vuetify from "./plugins/vuetify";
import "./assets/style.scss";
Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
