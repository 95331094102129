<script>
export default {
  props: {
    regions: Array,
  },

  data: () => ({
    menu: false,
  }),

  computed: {
    region: {
      get: (vm) => vm.$store.getters.getDefaultRegion || {},
      set(e) {
        this.$store.dispatch("setDefaultRegion", e);
      },
    },

    regionId: {
      get: (vm) => vm.$store.getters.getDefaultRegionId,
      set(e) {
        let region = this.regions.find((x) => x.id == e);

        if (e == this.region.id) return true;
        else if (region) {
          this.region = region;
          this.$nextTick(() => {
            this.$store.dispatch("resetAddOrder");
          });
        } else {
          this.regions
            .filter((x) => x.childs.length)
            .some((x) => {
              let child = x.childs.find((c) => c.id == e);
              if (child) {
                this.region = {
                  id: x.id,
                  name: x.name,
                  district: child,
                  childs: [],
                };

                return true;
              }
            });

          this.$nextTick(() => {
            this.$store.dispatch("resetAddOrder");
          });
        }
      },
    },

    district: (vm) => vm.region?.district || {},
  },
};
</script>

<template>
  <v-menu v-model="menu" min-width="250">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text dark v-bind="attrs" v-on="on" class="ml-3">
        {{ region.name }}

        <span v-if="district.id"> —{{ district.name }}</span>
        <v-icon> mdi-chevron-down </v-icon>
      </v-btn>
    </template>
    <v-card max-height="80vh">
      <v-list>
        <v-list-item-group v-model="regionId" mandatory>
          <template v-for="(region, key) in regions">
            <div v-if="region.childs.length" :key="`childs_${key}`">
              <div class="pl-4">
                <v-list-item-content>
                  <v-list-item-title v-text="region.name" />
                </v-list-item-content>
              </div>

              <v-list-item
                v-for="(child, inx) in region.childs"
                :value="child.id"
                :key="`i${inx}`"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <div class="pl-4">
                      <v-checkbox :input-value="active" />
                    </div>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title v-text="child.name" />
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider v-if="region.active" :key="`dv_${key}`" />
            </div>
            <v-list-item v-else :key="`parent_${key}`" :value="region.id">
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox :input-value="active"></v-checkbox>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="region.name" />
                </v-list-item-content>
              </template>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
  </v-menu>
</template>

<style lang="scss" scoped></style>
