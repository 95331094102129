<script>
import { mask } from "../service/input_mask.js";
import LogoutAlert from "../components/Modals/LogoutAlert.vue";

export default {
  components: {
    LogoutAlert,
  },

  data: () => ({
    isLogoutAlert: false,
    loading: false,
    radios: null,
    paymentTypes: [],
    shippingData: {},
    selectedPan: null,
    cards: [],
    sms: null,
    card_id: null,
    added_card_id: null,
    transaction_id: null,
    showSms: false,
    showAddNewCard: false,
  }),

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
    selectedPan(v) {
      if (v == "add_new") {
        this.showAddNewCard = true;
      } else {
        this.added_card_id = v;
        this.showAddNewCard = false;
      }
    },
  },

  computed: {
    orderToAdd: {
      get: (vm) => vm.$store.getters.getOrderToAdd,
      set(v) {
        this.$store.dispatch("setOrderToAdd", v);
      },
    },
    panOptions() {
      const options = [];

      // Check if cards is defined and has items
      if (this.cards && this.cards.length > 0) {
        // Push all card options
        options.push(
          ...this.cards.map((result) => ({
            text: result.pan,
            value: result.id,
          }))
        );
      }

      // Always add the "Add New" option
      options.push({
        text: "Add New",
        value: "add_new",
      });

      return options;
    },

    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,
    user: (vm) => vm.$store.getters.getUser,

    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  created() {
    if (this.orderToAdd.type == "shipping") {
      this.axios
        .post("v1/check-order?lang=" + this.$i18n.locale, this.orderToAdd)
        .then((res) => {
          if (res.data.status) {
            this.shippingData = res.data.data;
          } else if (res.data.error) {
            this.$router.go(-1);
            this.$store.dispatch("setSnkMsg", { text: res.data.error });
          }
        })
        .catch(() => {
          this.$router.go(-1);
        });
    }

    this.axios
      .get(
        `/v2/cards?type=sgopharm&username=${mask.unTel(this.orderToAdd.phone)}`
      )
      .then((res) => {
        this.cards = res.data.results;
      });

    this.axios.get("v1/order-options?lan=" + this.$i18n.locale).then((res) => {
      this.paymentTypes = getPayTypes(res.data.payment_types);

      function getPayTypes(types) {
        if (Array.isArray(types)) {
          return res.data.payment_types.map((x) => {
            if (x.name == "new") x.pan = "CLICK, UZCARD, HUMO";
            return x;
          });
        }
        return [];
      }
    });
  },

  mounted() {
    if (this.orderToAdd.drugs.length == 0) {
      this.$router.go(-1);
    }

    mask.tel();
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    updateOrder() {
      this.orderToAdd = { ...this.orderToAdd };
    },

    getIsCoords() {
      let [longitude, latitude] = this.orderToAdd.location
        ? this.orderToAdd.location.toString().split(",") || []
        : [];

      return Number(longitude) > 0 && Number(latitude) > 0;
    },

    onToOrder() {
      if (
        this.added_card_id !== null ||
        (this.sms !== null &&
          this.sms !== undefined &&
          this.sms.length == 6 &&
          this.orderToAdd.payment_type == 2)
      ) {
        let orderToAdd = { ...this.orderToAdd };
        orderToAdd.phone = mask.unTel(orderToAdd.phone);
        orderToAdd.sms_code = this.sms;
        orderToAdd.transaction_id = this.transaction_id;
        orderToAdd.added_card_id = this.added_card_id;
        if (orderToAdd.phone_number) {
          orderToAdd.phone_number = mask.unTel(orderToAdd.phone_number);
        }

        if (this.loading) return;
        this.loading = true;
        this.axios
          .post("v2/add-order", orderToAdd)
          .then((res) => {
            if (res.data.status > 0) {
              this.$store.dispatch("resetAddOrder").then(() => {
                this.$store
                  .dispatch("setOrderToAddNum", res.data.order_id)
                  .then(() => {
                    this.$router.replace("/searchDrugs");
                  });
              });
            } else {
              const text = res?.data?.msg || "error";
              this.$store.dispatch("setSnkMsg", { text, color: "error" });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.orderToAdd.payment_type == 2) {
        let cardNumber = this.orderToAdd.card_number;
        cardNumber = cardNumber.trim().replace(/\s+/g, "");
        let cardExpiry = this.orderToAdd.card_expiry.trim();
        let [month, year] = cardExpiry.split("/");
        let formattedExpiry = `${year}${month}`;
        let data = {
          card_number: cardNumber,
          expiry: formattedExpiry,
        };

        this.axios.post("v2/cards/init", data).then((res) => {
          if (res.data.status > 0) {
            this.orderToAdd.card_id = res.data.card_id;
            this.transaction_id = res.data.transaction_id;
            this.showSms = true;
          } else {
            this.$store.dispatch("setSnkMsg", { text: res.data.msg });
          }
        });
      } else if (
        this.$refs.cartForm.validate() ||
        this.orderToAdd.payment_type == 1
      ) {
        let orderToAdd = { ...this.orderToAdd };
        orderToAdd.phone = mask.unTel(orderToAdd.phone);
        if (orderToAdd.phone_number) {
          orderToAdd.phone_number = mask.unTel(orderToAdd.phone_number);
        }
        if (this.loading) return;
        this.loading = true;
        this.axios
          .post("v2/add-order", orderToAdd)
          .then((res) => {
            if (res.data.status) {
              this.$store.dispatch("resetAddOrder").then(() => {
                this.$store
                  .dispatch("setOrderToAddNum", res.data.order_id)
                  .then(() => {
                    this.$router.replace("/searchDrugs");
                  });
              });
            } else {
              const text = res?.data?.msg || "error";
              this.$store.dispatch("setSnkMsg", { text, color: "error" });
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <router-link
        tag="span"
        to="/home"
        class="c-pointer title d-flex ml-3 mr-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="s" />
        <span class="pl-3 logo-name">GoPharm</span>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <div @click="$router.replace('/searchDrugs')" class="py-1">
        <v-text-field
          v-if="$vuetify.breakpoint.mdAndUp"
          rounded
          readonly
          append-icon="mdi-magnify"
          flat
          dense
          hide-details
          :label="$t('Search for drugs...')"
          solo-inverted
        />
        <v-btn v-else dark icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </div>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn to="/ordersStore" text>
        {{ $t("Orders") }}
      </v-btn>
      <v-badge
        :content="newMessageNotifis.length"
        :value="newMessageNotifis.length"
        color="red"
        overlap
      >
        <v-btn to="/chat" icon small class="ml-2">
          <v-icon> mdi-email </v-icon>
        </v-btn>
      </v-badge>
      <v-menu min-width="260">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="isLogoutAlert = true">
            <v-list-item-avatar>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <LogoutAlert v-model="isLogoutAlert" />

    <v-main style="padding-top: 85px">
      <v-container class="pt-5">
        <v-row justify="center">
          <v-col cols="12" md="5" xl="3">
            <v-form ref="cartForm" onsubmit="return false;">
              <v-card>
                <v-card-title>
                  {{ $t("Payment") }}
                </v-card-title>
                <v-card-text>
                  <template v-if="this.orderToAdd.type == 'shipping'">
                    <div class="card-text">
                      <span class="fw-500">{{ $t("Delivery cost") }}:</span>
                      {{ shippingData.delivery_sum | sum }}
                      {{ $t("sum") }}
                    </div>
                    <div class="card-text">
                      <span class="fw-500"> {{ $t("Total price") }}:</span>
                      {{ shippingData.total | sum }}
                      {{ $t("sum") }}
                    </div>
                  </template>

                  <v-radio-group
                    name="payment_type"
                    required
                    :rules="[$valid.req]"
                    @change="updateOrder"
                    v-if="paymentTypes.length"
                    :label="$t('Payment type')"
                    v-model="orderToAdd.payment_type"
                    mandatory
                  >
                    <v-radio
                      v-for="(item, key) in paymentTypes"
                      :key="key"
                      :label="item.pan"
                      :value="item.id"
                    />
                  </v-radio-group>
                  <div
                    v-show="orderToAdd.payment_type != 1"
                    style="max-width: 350px"
                  >
                    <v-select
                      v-model="selectedPan"
                      :items="panOptions"
                      label="Select CARD"
                      item-text="text"
                      item-value="value"
                      dense
                      outlined
                    ></v-select>
                    <v-text-field
                      autocomplete="off"
                      @blur="updateOrder"
                      v-model="orderToAdd.card_number"
                      dense
                      outlined
                      placeholder="____ ____ ____ ____"
                      type="text"
                      label="Card number"
                      v-mask="'#### #### #### ####'"
                      :rules="[$valid.reqCardNumber]"
                      v-show="showAddNewCard"
                    />
                    <v-text-field
                      autocomplete="off"
                      @blur="updateOrder"
                      v-model="orderToAdd.card_expiry"
                      dense
                      outlined
                      placeholder="mm/dd"
                      v-mask="'##/##'"
                      label="Card expiry"
                      v-show="showAddNewCard"
                    />

                    <v-text-field
                      v-show="showSms"
                      outlined
                      dense
                      v-model="sms"
                      label="Sms code"
                      name="sms"
                      mask="'######"
                      required
                      :rules="[$valid.reqSms]"
                    />
                  </div>
                </v-card-text>
                <v-card-actions>
                  <div class="w-100">
                    <v-btn
                      :loading="loading"
                      @click="onToOrder"
                      dark
                      color="primary"
                      block
                    >
                      {{ $t("To order") }}
                    </v-btn>
                  </div>
                </v-card-actions>
              </v-card>
            </v-form>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<style scoped>
.theme--light .card-text {
  color: black;
}

@media only screen and (max-width: 410px) {
  .logo-name {
    display: none;
  }
}

.mapCard {
  border: 1px solid;
}
</style>
