<script>
export default {
  props: {
    title: String,
    width: {
      type: [String, Number],
      default: () => 550,
    },
    contentClass: {
      type: String,
      default: () => "px-6",
    },
    persistent: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Boolean,
      default: () => false,
    },
    closeable: {
      type: Boolean,
      default: () => true,
    },
    hideActions: {
      type: Boolean,
      default: () => false,
    },
  },

  data: () => ({
    //
  }),

  computed: {
    model: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    onClose() {
      this.$emit("close");

      this.$nextTick(() => {
        this.model = false;
      });
    },
  },
};
</script>

<template>
  <v-dialog :persistent="persistent" v-model="model" :width="width">
    <v-card color="bg-secondary" width="100%">
      <div class="d-flex px-3 pt-3">
        <h3 v-text="title" />
        <v-spacer />
        <v-btn v-if="closeable" class="close-btn" @click="onClose" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <v-card-text :class="contentClass">
        <slot />
      </v-card-text>

      <v-card-actions v-if="!hideActions" class="justify-center pb-6">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="scss" scoped></style>
