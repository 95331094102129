import Vue from "vue";
import VueRouter from "vue-router";
import Login from "./views/Auth/Login.vue";
import Home from "./views/Home.vue";
import OrdersStore from "./views/OrdersStore.vue";
import SearchDrugs from "./views/SearchDrugs.vue";
import Cart from "./views/Cart.vue";
import CartForWhom from "./views/CartForWhom.vue";
import CartPayment from "./views/CartPayment.vue";
import CartConfirmSms from "./views/CartConfirmSms.vue";
import WhoWillTake from "./views/WhoWillTake.vue";
import Chat from "./views/Chat.vue";
import ChatMessages from "./views/ChatMessages.vue";
import PleaseSelectChat from "./views/PleaseSelectChat.vue";

import authGuard from "./service/authGuard.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/home",
    component: Home,
    beforeEnter: authGuard,
    children: [
      {
        path: "/",
        name: "OrdersStore",
        component: OrdersStore,
      },
    ],
  },
  {
    path: "/searchDrugs",
    component: SearchDrugs,
    beforeEnter: authGuard,
  },
  {
    path: "/cart",
    component: Cart,
    beforeEnter: authGuard,
  },
  {
    path: "/cartForWhom",
    component: CartForWhom,
    beforeEnter: authGuard,
  },
  {
    path: "/cartPayment",
    component: CartPayment,
    beforeEnter: authGuard,
  },
  {
    path: "/cartConfirmSms",
    component: CartConfirmSms,
    beforeEnter: authGuard,
  },
  {
    path: "/whoWillTake",
    component: WhoWillTake,
    beforeEnter: authGuard,
  },
  {
    path: "/chat",
    component: Chat,
    beforeEnter: authGuard,
    children: [
      {
        path: "/",
        component: PleaseSelectChat,
      },
      {
        path: "/chatMessages/:id",
        component: ChatMessages,
      },
    ],
  },

  // {
  //   path: "/about",
  //   name: "About",
  //   component: () => import("./views/About.vue")
  // }

  {
    path: "*",
    component: Login,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
