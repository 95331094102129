<script>
export default {
  props: {
    isOpen: Boolean,
    imgUrl: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <div v-if="isOpen" class="modal" @click="closeModal">
    <div class="modal-content" @click.stop>
      <span class="close" @click="closeModal">&times;</span>
      <img :src="imgUrl" alt="Expanded Image" />
    </div>
  </div>
</template>
<style scoped>
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.modal-content {
  position: relative;
  padding: 10px;
  background-color: white;
  border-radius: 8px;
}
.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}
</style>
