<script>
export default {
  props: {
    startTime: {
      type: [String],
      required: true,
    },

    speed: {
      type: Number,
      default: 1000,
      required: false,
    },
  },

  data: () => ({
    currentTime: null,
  }),

  mounted() {
    setTimeout(this.countdown, 1);
  },

  methods: {
    countdown() {
      let t = Date.now() - Date.parse(this.startTime);

      let seconds = Math.floor((t / 1000) % 60);
      let minutes = Math.floor((t / 1000 / 60) % 60);
      let hours = Math.floor((t / (1000 * 60 * 60)) % 24);
      let days = Math.floor(t / (1000 * 60 * 60 * 24));

      if (t > 0) {
        this.currentTime = {
          total: t,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };

        setTimeout(this.countdown, this.speed);
      } else {
        this.currentTime = null;
      }
    },

    timerView() {
      const { days, hours, minutes, seconds } = this.currentTime;
      return `${sl(days)}:${sl(hours)}:${sl(minutes)}:${sl(seconds)}`;

      function sl(value) {
        return value ? `0${value}`.slice(-2) : "00";
      }
    },
  },
};
</script>

<template>
  <div>
    <div v-if="currentTime">
      {{ timerView() }}
    </div>
    <div v-else>00:00:00:00</div>
  </div>
</template>
