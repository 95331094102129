import Inputmask from "inputmask";

let inputmaskPlugin = {
  install: function (Vue) {
    Vue.directive("mask", {
      bind: function (el, binding) {
        Inputmask(binding.value).mask(el);
      },
    });
  },
};

export default inputmaskPlugin;
