<script>
export default {
  props: {
    value: {
      type: Number,
      required: false,
    },
  },

  data: () => ({
    //
  }),

  computed: {
    content: {
      get: (vm) => parseInt(vm.value) || 0,
      set(v) {
        let content = parseInt(v) || 0;
        content = content > 0 ? content : 0;
        this.$emit("input", content);
      },
    },
  },

  methods: {
    btnCounter(value) {
      let content = parseInt(this.content) || 0;
      if (value > 0) content += 1;
      else if (content >= 0) {
        content -= 1;
      }

      this.content = content;
    },
  },
};
</script>

<template>
  <div class="px-4">
    <div v-if="content" class="btnCounter">
      <v-layout align-center row>
        <v-flex shrink pa-0>
          <v-btn small @click="btnCounter(-1)" color="warning" class="btn1">
            <v-icon small dark>mdi-minus</v-icon>
          </v-btn>
        </v-flex>

        <v-flex shrink class="pt-1 px-0 pb-0">
          <input id="edcounter" type="number" v-model="content" />
        </v-flex>

        <v-flex shrink pa-0>
          <v-btn small @click="btnCounter(1)" color="warning" class="btn2">
            <v-icon small dark>mdi-plus</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </div>

    <div v-else>
      <v-layout row>
        <v-flex shrink pa-0>
          <v-btn
            outlined
            rounded
            color="primary"
            @click="btnCounter(1)"
            class="btn-cart"
          >
            <v-icon>mdi-cart</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<style>
.btn-cart {
  min-width: 92px !important;
  height: 40px !important;
}

.btnCounter .v-text-field {
  padding-top: 8px !important;
  margin-top: 0px !important;
}

#edcounter {
  width: 35px;
  text-align: center !important;
  padding-top: 0px !important;
}

.btn1 {
  border-radius: 50px 5px 0px 50px !important;
  -moz-border-radius: 50px 5px 0px 50px !important;
  -webkit-border-radius: 50px 5px 0px 50px !important;

  margin-right: 0px !important;
  padding-right: 0px !important;
  min-width: 20px !important;
  height: 38px !important;
}

.btn2 {
  border-radius: 0px 50px 50px 0px !important;
  -moz-border-radius: 0px 50px 50px 0px !important;
  -webkit-border-radius: 0px 50px 50px 0px !important;

  margin-left: 0px !important;
  padding-left: 0px !important;
  min-width: 20px !important;
  height: 38px !important;
}

.btn1 .v-btn--small {
  font-size: 13px;
  height: 38px;
  padding: 0 8px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
