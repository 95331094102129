/* eslint-disable no-console */
import sysConf from "./staticConfig.js";
import browserStore from "../service/browserStore.js";
import router from "../router.js";
// import { tel } from "../service/filters.js";
import i18n from "../i18n.js";

const notify = (msg) => {
  if (msg == true) {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then(() => {
        //
      });
    }
    return;
  } else {
    const { title, body, threadId } = msg;
    let options = {
      body: body,
      badge: "/img/icons/apple-touch-icon-60x60.png",
      icon: "/img/icons/apple-touch-icon-60x60.png",
      onclick: (event) => {
        event.preventDefault();
        router.push(window.location.hostname + "/chatMessages/" + threadId);
      },
    };

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else if (Notification.permission === "granted") {
      // eslint-disable-next-line no-unused-vars
      const notification = new Notification(title, options);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission((permission) => {
        if (permission === "granted") {
          // eslint-disable-next-line no-unused-vars
          const notification = new Notification(title, options);
        }
      });
    }
  }
};

export default (store) => {
  let token = browserStore.getTokenOnly();
  if (token) {
    notify(true);

    const socket = new WebSocket(sysConf.socketMessageUri + token);
    socket.onopen = () => {
      console.log("Message socket connected");
    };

    socket.onmessage = (event) => {
      if (event.data) {
        let data = JSON.parse(event.data);
        if (data && data.message) {
          store.dispatch("newMessage", { ...data.message });
          let id = store.getters.getThreadId;
          if (data.message.thread.id != id) {
            let msg = `${data.message.body}\n${data.message.thread.subject}`;
            notify({ title: i18n.t("New message!"), body: msg });
          }
        }
      }
      console.log("Data received from server");
    };
  }
};
