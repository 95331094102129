import browserStore from "../service/browserStore";
import store from "../store";

import router from "../router";

import config from "./staticConfig.js";

export default (axios) => {
  axios.defaults.baseURL = config.serverUrl + "/";

  axios.interceptors.request.use(
    (req) => {
      let token = browserStore.getToken();
      let isLoaded = browserStore.getSession("isLoaded");

      if (!isLoaded) {
        browserStore.setSession("isLoaded", true);
        // store.dispatch("setAppLoading", true);
      }

      if (token) req.headers.Authorization = token;

      if (req.method == "get" || req.method == "post") {
        let is = req.url.includes("?");
        let regionId = store.getters.getDefaultRegionId;
        req.url = `${req.url}${is ? "&" : "?"}region=${regionId}`;
      }

      return req;
    },

    (config) => {
      let token = browserStore.getToken();
      if (token) config.headers.Authorization = token;

      return config;
    },

    (error) => {
      if (error.response && error.response.status != undefined) {
        let status = error.response.status;
        let isNotAuth = status == 401 || status == 0;

        if (isNotAuth && router.currentRoute.path != "/") {
          browserStore.removeUser();
          setTimeout(() => {
            router.replace("/");
          }, 1);
        }
      }

      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,

    (error) => {
      if (error.response && error.response.status != undefined) {
        let status = error.response.status;
        let isNotAuth = status == 401 || status == 0;

        if (isNotAuth && router.currentRoute.path != "/") {
          browserStore.removeUser();
          setTimeout(() => {
            router.replace("/");
          }, 1);
        }
      }

      return Promise.reject(error);
    }
  );
};
