import browserStore from "./browserStore";
import pagePermissions from "./pagePermissions";

export default (to, from, next) => {
  const userData = browserStore.getUser();

  if (to.path != "/") {
    next();
    return;
  }

  if (
    !userData ||
    (userData && userData.user && Array.isArray(userData.user.groups))
  ) {
    if (from.path != "/") {
      next("/");
    }
    return;
  }

  let isManager = userData.user.groups.find((x) => x.name == "manager");

  if (isManager || pagePermissions(userData.user.groups, to.path)) {
    if (from.path != "/") {
      next(userData.token ? true : "/");
    }
  }
};
