<script>
export default {
  props: {
    value: {
      type: [Boolean, Number],
      required: false,
    },
    item: Number,
  },

  data: () => ({
    //
  }),

  computed: {
    dialog: {
      get: (vm) => vm.value,

      set(v) {
        this.$emit("input", v);
      },
    },
  },

  methods: {
    onClose() {
      this.dialog = false;
    },
  },
};
</script>

<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" max-width="400px">
      <v-card>
        <v-card-title class="pb-5 d-flex">
          <v-spacer />

          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="card-text">
          <div class="title text-center">
            {{ $t("Order number") }}
          </div>

          <div class="display-1 text-center pt-2">
            {{ item }}
          </div>
        </v-card-text>
        <v-card-actions>
          <div class="px-9 pt-4 pb-2 w-100">
            <v-btn color="primary" block dark @click="onClose">
              {{ $t("Close") }}
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
