import Inputmask from "inputmask";
export const mask = {
  tel() {
    Inputmask({
      mask: "+\\9\\98 99 999-99-99",
    }).mask(document.querySelectorAll("input[type=tel]"));
    // autoUnmask: true
    // nullable: false
    // autoUnmask: false
  },

  number(selector, max = 0) {
    Inputmask({
      alias: "numeric",
      groupSeparator: " ",
      digits: 0,
      min: 0,
      max,
      positionCaretOnClick: "select",
      autoUnmask: true,
    }).mask(document.querySelectorAll(selector));
  },

  unTel: (v) => Inputmask.unmask(v, { alias: "999999999999" }),
};
