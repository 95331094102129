import axios from "../service/axios";
// import i18n from "../i18n";

export default {
  state: {
    threads: [],
    messages: [],
    newMessageNotifis: [],
    threadId: 0,
  },

  mutations: {
    setThreadId(state, threadId) {
      state.threadId = threadId;
    },

    setThreads(state, threads) {
      state.threads = threads;
    },

    setMessages(state, messages) {
      state.messages = messages;
    },

    addMessage(state, message) {
      if (Array.isArray(state.messages)) {
        state.messages.push(message);
      } else {
        state.messages = [message];
      }
    },

    addThread(state, thread) {
      let old = state.threads.find((x) => x.id == thread.id);
      if (old) return;

      if (Array.isArray(state.threads)) {
        state.threads.unshift(thread);
      } else {
        state.threads = [thread];
      }
    },

    addThreadNotifi(state, thread) {
      let old = state.newMessageNotifis.find((x) => x.id == thread.id);
      if (old) {
        state.newMessageNotifis = state.newMessageNotifis.map((x) => {
          if (x.id == thread.id) {
            x.count += 1;
          }

          return x;
        });
      } else {
        thread.count = 1;
        if (Array.isArray(state.newMessageNotifis)) {
          state.newMessageNotifis.unshift(thread);
        } else {
          state.newMessageNotifis = [thread];
        }
      }
    },

    removeThreadNotifi(state, threadId) {
      state.newMessageNotifis = state.newMessageNotifis.filter(
        (x) => x.id != threadId
      );
    },
  },

  actions: {
    async initThreads({ commit }) {
      try {
        const res = await axios.get(`/chat/threads`);
        let chats = Array.isArray(res.data.results) ? res.data.results : [];
        let threadNotifi = chats.filter((x) => x.unread_count > 0);
        if (threadNotifi.length) {
          threadNotifi.forEach((noti) => {
            commit("addThreadNotifi", noti);
          });
        }

        commit("setThreads", chats);
      } catch {
        //
      }
    },

    setMessages({ commit }, messages) {
      commit("setMessages", messages);
    },

    setThreadId({ commit }, threadId) {
      commit("setThreadId", threadId);
    },

    removeThreadNotifi({ commit }, threadId) {
      commit("removeThreadNotifi", threadId);
    },

    async initMessages({ commit }, threadId) {
      try {
        const res = await axios.get("chat/messages?thread=" + threadId);
        commit("setThreadId", threadId);
        if (Array.isArray(res.data.results)) {
          let messages = res.data.results.reverse();
          // .sort((a, b) => {
          //   return new Date(a.created_at) - new Date(b.created_at);
          // });
          commit("setMessages", messages);
        }
      } catch {
        //
      }
    },

    async sendMessages(ctx, msg) {
      try {
        const res = await axios.post("chat/send-message", msg);
        return res.data.status;
      } catch {
        //
      }
    },

    async newMessage({ commit, state }, msg) {
      if (state.threadId == msg.thread.id) {
        delete msg.thread;
        commit("addMessage", msg);
      } else {
        commit("addThreadNotifi", msg.thread);
        commit("addThread", msg.thread);
      }
    },
  },

  getters: {
    getThreads: (state) => state.threads,
    getThreadId: (state) => state.threadId,
    getNewMessageNotifis: (state) => state.newMessageNotifis,
    getMessages: (state) => state.messages,
  },
};
