import sysConf from "./staticConfig.js";
import browserStore from "../service/browserStore.js";

export default (callback, onSocket) => {
  let token = browserStore.getTokenOnly();
  if (token) {
    const socket = new WebSocket(sysConf.socketDriversUri + token);
    socket.onopen = () => {
      console.log("Drivers socket connected");
    };

    socket.onmessage = (event) => {
      if (event?.data) {
        let data = JSON.parse(event.data);
        if (data?.driver) callback(data.driver);
      }
      console.log("Data received from server");
    };

    onSocket(socket);
  }
};
