<script>
import SearchDrugsInput from "../components/SearchDrugsInput.vue";
import BtnCounter from "../components/BtnCounter.vue";

import RegionDefault from "../components/Modals/RegionDefault.vue";

import LogoutAlert from "../components/Modals/LogoutAlert.vue";
import ShowOrderNum from "../components/Modals/ShowOrderNum.vue";

export default {
  components: {
    LogoutAlert,
    BtnCounter,
    SearchDrugsInput,
    ShowOrderNum,
    RegionDefault,
  },

  data: () => ({
    isLogoutAlert: false,
    minSum: 0,
    regions: [],
  }),

  watch: {
    "$i18n.locale"(v) {
      this.$bStore.setLocal("lang", v);
    },
  },

  created() {
    this.axios.get("v1/order-minimum").then((res) => {
      this.minSum = parseInt(res.data.min) || 0;
    });

    let lang = this.$i18n.locale;
    this.axios.get(`v1/regions?lan=${lang}`).then((res) => {
      let regions = Array.isArray(res.data) ? res.data : [];

      this.regions = regions;
    });
  },

  computed: {
    selectedDrugs: (vm) => vm.$store.getters.getSelectedDrugs,
    orderToAdd: (vm) => vm.$store.getters.getOrderToAdd,
    newMessageNotifis: (vm) => vm.$store.getters.getNewMessageNotifis,
    orderToAddNum: {
      get: (vm) => vm.$store.getters.getOrderToAddNum,
      set(v) {
        this.$store.dispatch("setOrderToAddNum", v);
      },
    },
    user: (vm) => vm.$store.getters.getUser,

    getFullName() {
      let name = this.user.user.first_name;
      let lastName = this.user.user.last_name;

      if (window.outerWidth < 840) return null;
      if (!name?.trim()) return null;

      return `${name}${lastName ? ` ${lastName}` : ""}`;
    },
  },

  methods: {
    onThemeChange() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$bStore.setLocal("$vuetify.theme.dark", this.$vuetify.theme.dark);
    },

    onLogOut() {
      this.isLogoutAlert = true;
      // this.$bStore.removeUser();
      // this.$router.replace({ name: "Login" });
    },

    onSelectDrug(drug) {
      drug.qty = 1;
      this.$store.dispatch("addSelectedDrug", drug).then(() => {
        this.$store.dispatch("addToCart", drug);
      });
    },

    onAddToCart(value, drug) {
      drug.qty = value;
      this.$store.dispatch("addToCart", drug);
    },

    getTotal(drug) {
      let qty = parseInt(drug.qty) || 0;
      if (qty == 0) return 0;
      return qty * drug.price;
    },

    getFullTotal() {
      let drugs = this.orderToAdd.drugs.map((x) => x.qty * x._price);
      return drugs.reduce((a, b) => a + b, 0);
    },

    onRemoveSelectedDrug(drugId) {
      this.$store.dispatch("removeSelectedDrug", drugId);
    },

    ordersCount() {
      return this.orderToAdd.drugs.length;
    },

    getManufacturer: (item) => item?.manufacturer?.name,
  },
};
</script>

<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? '' : 'primary'"
      elevation="3"
      app
      clipped-left
      dark
    >
      <router-link
        tag="span"
        to="/home"
        class="go-logo c-pointer title d-flex ml-3 mr-md-10"
      >
        <img src="/img/icons/favicon-32x32.png" alt="logo" />
        <span class="pl-3">GoPharm</span>
      </router-link>
      <v-spacer v-if="!$vuetify.breakpoint.mdAndUp" />
      <SearchDrugsInput @select="onSelectDrug" />
      <RegionDefault :regions="regions" />
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
      <v-btn to="/ordersStore" text>
        {{ $t("Orders") }}
      </v-btn>
      <v-badge
        :content="orderToAdd.drugs.length"
        :value="orderToAdd.drugs.length"
        color="red"
        overlap
        offset-x="20"
        offset-y="20"
      >
        <v-btn
          class="ml-2"
          :disabled="ordersCount() == 0 || getFullTotal() < minSum"
          to="/cart"
          icon
        >
          <v-icon> mdi-cart </v-icon>
        </v-btn>
      </v-badge>
      <v-badge
        offset-x="20"
        offset-y="20"
        :content="newMessageNotifis.length"
        :value="newMessageNotifis.length"
        color="red"
        overlap
      >
        <v-btn to="/chat" icon class="ml-2">
          <v-icon> mdi-email </v-icon>
        </v-btn>
      </v-badge>
      <v-menu min-width="260">
        <template v-slot:activator="{ on: menu, attrs }">
          <v-btn
            :icon="!getFullName"
            :color="getFullName ? 'transparent' : ''"
            v-bind="attrs"
            v-on="menu"
            class="ml-2"
          >
            <v-icon>mdi-account-cog</v-icon>
            <template v-if="getFullName">
              <span class="pl-2" v-text="getFullName" />
            </template>
          </v-btn>
        </template>
        <v-list>
          <v-list-item-group v-model="$vuetify.theme.dark">
            <v-list-item @click="onThemeChange" :value="true">
              <v-list-item-avatar>
                <v-icon v-if="$vuetify.theme.dark">
                  mdi-white-balance-sunny
                </v-icon>
                <v-icon v-else> mdi-weather-night </v-icon>
              </v-list-item-avatar>
              <v-list-item-title>
                {{ $t("darkMode") }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-subheader>{{ $t("languageOfApp") }}</v-subheader>
          <v-list-item-group v-model="$i18n.locale">
            <v-list-item value="uz">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/uz.png" />
              </v-list-item-avatar>
              <v-list-item-title>O'zbek tili</v-list-item-title>
            </v-list-item>
            <v-list-item value="ru">
              <v-list-item-avatar>
                <v-img src="../assets/img/flags/ru.png" />
              </v-list-item-avatar>
              <v-list-item-title>Русский язык</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item @click="onLogOut">
            <v-list-item-avatar>
              <v-icon>mdi-lock</v-icon>
            </v-list-item-avatar>
            <v-list-item-title>{{ $t("logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <ShowOrderNum
      v-if="orderToAddNum"
      v-model="orderToAddNum"
      :item="orderToAddNum"
    />

    <LogoutAlert v-model="isLogoutAlert" />

    <v-main style="padding-top: 85px">
      <v-container>
        <v-row>
          <template v-if="selectedDrugs.length">
            <v-col
              v-for="(item, index) in selectedDrugs"
              :key="index"
              cols="12"
              md="4"
              xl="3"
            >
              <v-card
                height="100%"
                elevation="5"
                style="
                  display: flex;
                  flex-wrap: wrap;
                  align-content: space-between;
                "
              >
                <div class="w-100">
                  <v-list-item three-line>
                    <v-list-item-avatar
                      @click="$tools.openImg(item.image_thumbnail)"
                      tile
                      size="90"
                      color="grey"
                    >
                      <v-img
                        lazy-src="/media/drugs-thumbnail/default.png"
                        :src="item.image_thumbnail"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="headline mb-1">
                        {{ item.price | sum }} {{ $t("sum") }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        <template v-if="item.international_name">
                          {{ item.international_name.name }}
                        </template>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-text>
                    <div class="card-text">
                      <div>
                        {{ item.name }}
                      </div>
                      <div>
                        {{ getManufacturer(item) }}
                      </div>
                      <div class="fw-500 pt-1">
                        {{ $t("Total price") }}:
                        {{ getTotal(item) | sum }}
                        {{ $t("sum") }}
                      </div>
                    </div>

                    <template v-if="item.remains.length">
                      <div class="card-text fw-500">
                        {{ $t("Branch") }}
                      </div>
                      <div class="card-text">
                        <div
                          v-for="(remain, index) in item.remains"
                          :key="'r' + index"
                        >
                          {{ $t("Count") }}: {{ remain.qty }} —
                          {{ remain.store }}
                        </div>
                      </div>
                    </template>
                    <!-- <div>
                  <template v-if="item.unit">
                    {{ item.unit.name }}
                  </template>
                  <template v-if="item.category">
                    <br />
                    {{ item.category.name }}
                  </template>
                </div> -->
                  </v-card-text>
                </div>
                <v-card-actions class="w-100 pb-7" style="height: 50px">
                  <v-spacer />
                  <v-btn
                    @click="onRemoveSelectedDrug(item.id)"
                    color="red"
                    class="btn-remove mr-3"
                    dark
                    rounded
                    outlined
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <BtnCounter
                    :value="item.qty"
                    @input="onAddToCart($event, item)"
                  />
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col cols="12" class="text-right pt-5">
              <v-btn
                :disabled="ordersCount() == 0 || getFullTotal() < minSum"
                to="/cart"
                dark
                color="primary"
                class="px-10"
              >
                {{ $t("To order") }}
              </v-btn>
            </v-col>
          </template>

          <v-col v-else cols="12">
            {{ $t("Find the medicine you need and order it!") }}
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<style scoped>
.theme--light .card-text {
  color: black;
}

@media only screen and (max-width: 460px) {
  .go-logo span {
    display: none;
  }
}

@media only screen and (max-width: 355px) {
  .go-logo {
    display: none !important;
  }
}

.btn-remove {
  min-width: 90px !important;
  height: 39px !important;
}
</style>
