<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import sysConfig from "../utils/staticConfig.js";

export default {
  components: { yandexMap, ymapMarker },

  props: {
    markerId: {
      type: [String, Number],
      default: function () {
        return this.$tools.getUniq();
      },
      required: false,
    },

    coords: {
      type: Array,
      required: true,
    },

    zoom: {
      type: [String, Number],
      default: () => 13,
    },
  },

  data: () => ({
    settings: {
      apiKey: sysConfig.yandexApi,
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    },
  }),

  computed: {
    _coords: {
      get: (vm) => vm.coords,
      set(coords) {
        this.$emit("coords", coords);
      },
    },
  },
};
</script>

<template>
  <yandex-map
    class="h-100 w-100"
    :zoom="zoom"
    :settings="settings"
    :coords="_coords"
  >
    <ymap-marker :coords="_coords" :markerId="markerId" />
  </yandex-map>
</template>

<style scoped>
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}
</style>
